import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DndContext, closestCenter, DragOverlay } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import Callout from '../Callout';
import DroppableColumn from './DroppableColumn';
import SortableCourse from './SortableCourse';
import CourseCard from './CourseCard2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faList, faDownload } from '@fortawesome/free-solid-svg-icons';
import loadingGif from '../../assets/images/animation.gif';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import InputOverlay from '../InputOverlay'; 
import Button from '../Button';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'; // For sliding

const FourYearPlan = () => {
  const [isLoading, setIsLoading] = useState(true); 

  const [courses, setCourses] = useState({});
  const [activeId, setActiveId] = useState(null);
  const [view, setView] = useState('grid'); // Default view set to 'grid'
  const [errorMessage, setErrorMessage] = useState(''); // State to manage error messages
  const termOrder = { 'Spring': 1, 'Summer': 2, 'Fall': 3 };
  const [studentName, setStudentName] = useState('');

  const [academicYearLabels, setAcademicYearLabels] = useState({});


  // const [customLabels, setCustomLabels] = useState({
  //   '9th Grade': '9th Grade',
  //   '10th Grade': '10th Grade',
  //   '11th Grade': '11th Grade',
  //   '12th Grade': '12th Grade',
  // });

  const [editLabel, setEditLabel] = useState('');
  const [currentGrade, setCurrentGrade] = useState('');
  const [isEditOverlayVisible, setIsEditOverlayVisible] = useState(false);


  const [isAddYearOverlayVisible, setIsAddYearOverlayVisible] = useState(false);
  const [newYearLabel, setNewYearLabel] = useState('');

  const handleAddYearClick = () => {
    setIsAddYearOverlayVisible(true);
  };

  const handleAddYear = async (e) => {
  e.preventDefault();
  if (newYearLabel) {
    // Get the next key as a string by finding the length of the existing labels
    const nextKey = (Object.keys(academicYearLabels).length + 1).toString();

    // Create the updated labels with the new year added
    const updatedLabels = { ...academicYearLabels, [nextKey]: newYearLabel };
    setAcademicYearLabels(updatedLabels);
    setIsAddYearOverlayVisible(false);
    setNewYearLabel(''); // Reset input field
  
    try {
      // Update the new academic year labels in the backend
      await axios.put(`${API_ENDPOINT}/api/update-user-labels/`, 
        { academic_year_labels: updatedLabels }, 
        { headers: { Authorization: `Token ${localStorage.getItem('token')}` } }
      );
    } catch (error) {
      console.error('Error adding academic year:', error);
    }
  }
};

  const addYearForm = (
    <form onSubmit={handleAddYear}>
      <div className="mb-3">
        <input 
          type="text" 
          className="form-control" 
          value={newYearLabel} 
          onChange={(e) => setNewYearLabel(e.target.value)} 
          placeholder="Enter academic year name"
        />
      </div>
      <Button type="submit" className="btn btn-primary">Add</Button>
    </form>
  );

  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Token ${token}`,
  };
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  useEffect(() => {
    const fetchCustomLabels = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/current-user/`, {
          headers: { Authorization: `Token ${localStorage.getItem('token')}` }
        });
        const { academic_year_labels } = response.data;
        
  
        if (academic_year_labels) {
          setAcademicYearLabels(academic_year_labels);  // Store the labels directly from the server
        }
      } catch (error) {
        console.error('Error fetching academic year labels:', error);
      }
    };
    
    fetchCustomLabels();
  }, []);

  const findKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  const handleEditClick = (grade) => {
    // Find the key by the grade value (e.g., "10th Grade")
    const key = findKeyByValue(academicYearLabels, grade);
    
    if (key) {
      // Use the numeric key to access the label
      setEditLabel(academicYearLabels[key]);
      setCurrentGrade(key);  // Store the numeric key as the current grade being edited
      setIsEditOverlayVisible(true);  // Show the overlay
    } else {
      console.error('Grade not found in academicYearLabels:', grade);
    }
  };

  const handleLabelChange = async (e) => {
    e.preventDefault();
    const updatedLabels = { ...academicYearLabels, [currentGrade]: editLabel };
  
    try {
      // Update labels in the backend first
      const response = await axios.put(`${API_ENDPOINT}/api/update-user-labels/`, 
        { academic_year_labels: updatedLabels }, 
        { headers: { Authorization: `Token ${localStorage.getItem('token')}` } }
      );
      // After successful update, update the state
      setAcademicYearLabels(updatedLabels);
      setIsEditOverlayVisible(false);
    } catch (error) {
      console.error('Error updating custom labels:', error);
    }
  };

  const handleDeleteYear = async (e) => {
    e.preventDefault();
    const isConfirmed = window.confirm('Are you sure you want to delete this academic year? This action cannot be undone.');
  
    if (!isConfirmed) return;
  
    // Check if there are any courses in the academic year
    if (courses[currentGrade] && courses[currentGrade].length > 0) {
      setErrorMessage('Cannot delete an academic year that has courses.');
      setIsEditOverlayVisible(false);
      return;
    }
  
    try {
      // Remove the academic year label in the backend first
      const updatedLabels = { ...academicYearLabels };
      delete updatedLabels[currentGrade];
  
      const response = await axios.put(`${API_ENDPOINT}/api/update-user-labels/`, 
        { academic_year_labels: updatedLabels }, 
        { headers: { Authorization: `Token ${localStorage.getItem('token')}` } }
      );
  
      // Only update state after a successful API call
      setAcademicYearLabels(updatedLabels);
      setErrorMessage('');
    } catch (error) {
      console.error('Error deleting academic year:', error);
      setErrorMessage('Error deleting the academic year.');
    } finally {
      setIsEditOverlayVisible(false);
    }
  };



  const editLabelForm = (
    <form onSubmit={handleLabelChange}>
      <div className="mb-3">
      <input 
        type="text" 
        className="form-control" 
        id="gradeLabel" 
        value={editLabel}  // Make sure editLabel is linked correctly to the input
        onChange={(e) => setEditLabel(e.target.value)}  // Handle updates to the field
      />
      </div>
  
      {/* Save button */}
      <Button type="submit" className="btn btn-primary">Save</Button>
  
      {/* Add some spacing between the buttons */}
      <div style={{ marginTop: '10px' }}>
        {/* Delete button placed below the save button */}
        <Button 
          type="button" 
          className="btn btn-danger"
          onClick={handleDeleteYear}
        >
          Delete Academic Year
        </Button>
      </div>
    </form>
  );

  useEffect(() => {
    const fetchScheduledCourses = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/user-scheduled-courses/`, { headers });
        const fetchedCourses = response.data;

        const sortedCourses = fetchedCourses.sort(sortCoursesByAcademicTerm);

        const coursesByTerm = sortedCourses.reduce((acc, course) => {
          const { term } = course;
          if (!acc[term]) {
            acc[term] = [];
          }
          acc[term].push(course);
          return acc;
        }, {});

        setCourses(coursesByTerm);
        setIsLoading(false); 
      } catch (error) {
        console.error('Error fetching scheduled courses:', error);
      }
    };

    const fetchStudentName = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/current-user/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`
          }
        });
        setStudentName(`${response.data.first_name} ${response.data.last_name}`);
      } catch (error) {
        console.error("Error fetching student name:", error);
      }
    };

    fetchScheduledCourses();
    fetchStudentName();
  }, []);

  const sortCoursesByAcademicTerm = (a, b) => {
    const yearA = parseInt(a.term.split(' ')[1], 10);
    const yearB = parseInt(b.term.split(' ')[1], 10);
    const termA = a.term.split(' ')[0];
    const termB = b.term.split(' ')[0];

    if (yearA !== yearB) {
      return yearA - yearB;
    } else {
      return termOrder[termA] - termOrder[termB];
    }
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event) => {
    console.log(event);
    const { active, over } = event;
    // console.log(over["id"]);
    setActiveId(null);
  
    if (over) {
      const [activeTerm, activeIndex] = active.id.split('-');
  
      // Safely check if over.data and over.data.current exist before accessing sortable.containerId
      const [overTerm] = over.id.split('-');  // Cleaner approach to split off the '-0'
  
      const overLabel = overTerm;
  
      console.log(overTerm); // For debugging
      console.log('Moving course from', activeTerm, 'to', overLabel); // For debugging
  
      if (overLabel) {  // Check if the target column is valid
        if (activeTerm === overLabel) {
          const newItems = arrayMove(courses[activeTerm], parseInt(activeIndex), parseInt(over.id.split('-')[1]));
          setCourses(prev => ({ ...prev, [activeTerm]: newItems }));
        } else {
          const activeCourse = courses[activeTerm][parseInt(activeIndex)];
          const newActiveTermItems = courses[activeTerm].filter((_, index) => index !== parseInt(activeIndex));
          const newOverTermItems = [...(courses[overLabel] || []), activeCourse];
  
          // Temporarily update the state to show the change
          setCourses(prev => ({
            ...prev,
            [activeTerm]: newActiveTermItems,
            [overLabel]: newOverTermItems
          }));
  
          // Update the term in the backend
          try {
            await axios.put(
              `${API_ENDPOINT}/api/courses/${activeCourse.id}/`,
              { term: overLabel },  // Pass the new term
              { headers }
            );
            setErrorMessage('');  // Clear any previous error messages on success
          } catch (error) {
            console.error('Error updating course term:', error);
            alert((error.response && error.response.data && error.response.data.error) || 'An error occurred while updating the course term.');
  
            // Revert the state change if there is an error
            setCourses(prev => ({
              ...prev,
              [activeTerm]: [...newActiveTermItems, activeCourse],
              [overLabel]: newOverTermItems.filter(course => course.id !== activeCourse.id)
            }));
          }
        }
      }
    }
  };


  const handleExportAsPDF = () => {
    const input = document.getElementById('four-year-plan'); // Reference to the component to be exported
  
    html2canvas(input, { scale: 2 }).then((canvas) => {
      // Create a new canvas with margins
      const margin = 50; // Define the margin size
      const canvasWithMargin = document.createElement('canvas');
      canvasWithMargin.width = canvas.width + margin * 2;
      canvasWithMargin.height = canvas.height + margin * 2;
      const ctx = canvasWithMargin.getContext('2d');
  
      // Fill the new canvas with white background (optional)
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvasWithMargin.width, canvasWithMargin.height);
  
      // Draw the original canvas onto the new canvas with margins
      ctx.drawImage(canvas, margin, margin);
  
      // Convert the new canvas to a data URL
      const imgData = canvasWithMargin.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  
      // Open PDF in a new tab
      const pdfUrl = pdf.output('bloburl');
      window.open(pdfUrl, '_blank');
    });
  };
  
  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const toggleButtonStyle = {
    display: 'flex',
    border: '1px solid #ccc',
    borderRadius: '5px',
    overflow: 'hidden',
  };

  const toggleOptionStyle = {
    border: 'none',
    padding: '5px 10px',
    cursor: 'pointer',
  };

  const activeToggleOptionStyle = {
    backgroundColor: '#ccc',
  };

  const visibleYears = Object.keys(academicYearLabels)
  .sort((a, b) => parseInt(a) - parseInt(b));

  const handleScrollLeft = () => {
    const container = document.getElementById('four-year-plan-container');
    container.scrollBy({ left: -200, behavior: 'smooth' });
  };
  
  const handleScrollRight = () => {
    const container = document.getElementById('four-year-plan-container');
    container.scrollBy({ left: 200, behavior: 'smooth' });
  };


  return (
    <div className="page-container" id="four-year-plan">
      <div style={headerStyle}>
        <h1> {studentName} - Academic Plan</h1>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ ...toggleButtonStyle, marginRight: '10px', ...activeToggleOptionStyle }}>
            <button
              style={{ ...toggleOptionStyle }}
              onClick={handleExportAsPDF}
            >
              <FontAwesomeIcon icon={faDownload} />
            </button>
          </div>
          <div style={toggleButtonStyle}>
            <button
              style={{ ...toggleOptionStyle, ...(view === 'grid' ? activeToggleOptionStyle : {}) }}
              onClick={() => setView('grid')}
            >
              <FontAwesomeIcon icon={faTh} />
            </button>
            <button
              style={{ ...toggleOptionStyle, ...(view === 'list' ? activeToggleOptionStyle : {}) }}
              onClick={() => setView('list')}
            >
              <FontAwesomeIcon icon={faList} />
            </button>
          </div>
        </div>
      </div>
      
      {/* Add Academic Year Button */}
      <div>
        <Button variant="primary" onClick={handleAddYearClick}>Add Academic Year</Button>
      </div>
  
      {/* Callout */}
      <Callout text="The Academic Plan is a comprehensive blueprint of all the courses you have taken or plan to take during your academic journey. It serves as a strategic roadmap, guiding you through the intricacies of your education, ensuring that you meet all the necessary requirements and milestones along the way." />
      
      {/* Error Message */}
      {errorMessage && <div style={{ color: 'red'}}>{errorMessage}</div>}

      
  
      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <img src={loadingGif} alt="Loading..." style={{ width: '100px', height: '100px', marginBottom: '0px' }} />
        </div>
      ) : (
        view === 'list' ? (
          <div style={{ marginTop: '20px' }}>
          {visibleYears.map((order) => {
              const grade = academicYearLabels[order];  // The value in the dictionary is the label
              return (
                <div key={grade}>
              <h2>{grade}</h2>
              {courses[grade] && courses[grade].length > 0 ? (
                courses[grade].map(scheduledCourse => (
                  <CourseCard key={scheduledCourse.id} course={scheduledCourse.course} />
                ))
              ) : (
                <p>No courses scheduled for this year.</p>
              )}
            </div>
              );
            })}
        </div>
        ) : (
          <div style={{ display: 'flex', overflowX: 'auto',  flexGrow: 1 }}>
          <DndContext 
            collisionDetection={closestCenter} 
            onDragStart={handleDragStart} 
            onDragEnd={handleDragEnd}>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px', flexGrow: 1 }}>
            {visibleYears.map((order) => {
              const gradeLabel = academicYearLabels[order];  // The value in the dictionary is the label
              return (
                <DroppableColumn 
                  key={gradeLabel} 
                  id={gradeLabel}  // Use order as id
                  term={gradeLabel}  // Use the value (e.g., '9th Grade') as the term
                  courses={courses[gradeLabel] || []}  // Match courses with the value, not the key
                  title={gradeLabel}  // Display the value (label) as the title
                  onEditClick={handleEditClick} 
                />
              );
            })}
            </div>
            <DragOverlay>
              {activeId ? <SortableCourse id={activeId} course={courses[activeId.split('-')[0]][parseInt(activeId.split('-')[1])].course} /> : null}
            </DragOverlay>
          </DndContext>
          </div>
        )
      )}
  
      {/* Add Year Overlay */}
      {isAddYearOverlayVisible && (
        <InputOverlay
          title="Add Academic Year"
          description="Enter a new academic year label."
          onClose={() => setIsAddYearOverlayVisible(false)}
          content={addYearForm}
        />
      )}
  
      {/* Edit Label Overlay */}
      {isEditOverlayVisible && (
        <InputOverlay
          title={`Edit Academic Year`}
          description={`Edit the label for this academic year.`}
          onClose={() => setIsEditOverlayVisible(false)}
          content={editLabelForm}
        />
      )}
    </div>
  );
};

export default FourYearPlan;

