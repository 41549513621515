import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad } from '@fortawesome/free-solid-svg-icons';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'; // Assuming you have a reusable Button component
import InputOverlay from '../InputOverlay'; // Adjust the path as necessary
import axios from 'axios';


const CoursePage = () => {
  const [isLoading, setIsLoading] = useState(true); 
  const [isCourseScheduled, setIsCourseScheduled] = useState(false);


  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  
  const { id } = useParams(); // Use the correct param name as used in your route
  const navigate = useNavigate();
  const [course, setCourse] = useState(null); // State to store the fetched course data
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const token = localStorage.getItem('token'); // Assuming you store the token in localStorage


  const [year, setYear] = useState('');
  const [courseId, setCourseId] = useState('');
  const [courseTitle, setCourseTitle] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [courseUnits, setCourseUnits] = useState('');
  const [courseSubject, setCourseSubject] = useState('');
  const [academicYearOptions, setAcademicYearOptions] = useState([]); // State for academic year options


  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const headers = {
          Authorization: `Token ${token}`, // Include the token in the request headers
        };
        const response = await axios.get(`${API_ENDPOINT}/api/get-course/${id}/`, { headers });
        const courseData = response.data;
        setCourse(courseData);

        // Initialize form fields with fetched course data
        setCourseId(courseData.course_id);
        setCourseTitle(courseData.title);
        setCourseDescription(courseData.description);
        setCourseUnits(courseData.units);
        setCourseSubject(courseData.subject);
      } catch (error) {
        console.error('Error fetching course:', error);
        // Handle error, e.g., navigate to an error page or show an error message
      }
      setIsLoading(false); 
    };

    const checkCourseScheduled = async () => {
      try {
        const headers = { Authorization: `Token ${token}` };
        const response = await axios.get(`${API_ENDPOINT}/api/check-course-scheduled/${id}/`, { headers });
        setIsCourseScheduled(response.data.isScheduled);
      } catch (error) {
        console.error('Error checking course scheduled status:', error);
      }
    };

    const fetchAcademicYearLabels = async () => {
      try {
        const headers = { Authorization: `Token ${token}` };
        const response = await axios.get(`${API_ENDPOINT}/api/current-user/`, { headers });
        const { academic_year_labels } = response.data;
        setAcademicYearOptions(Object.values(academic_year_labels)); // Set the academic year options
      } catch (error) {
        console.error('Error fetching academic year labels:', error);
      }
    };
  
  
    if (id) {
      fetchCourse();
      checkCourseScheduled();
      fetchAcademicYearLabels();
    }
  }, []); // Include uuid and token in the dependency array
  



  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
};
  

const addCourse = async (e) => {
  e.preventDefault(); // Prevent default form submission behavior

  const scheduledCourseData = {
    course_id: id, // Assuming the course ID is the one from useParams
    term: year,
  };

  const headers = {
    Authorization: `Token ${token}`, // Use your existing token
    'Content-Type': 'application/json',
  };

  try {
    // Adjust the URL to match your endpoint for adding a scheduled course
    const response = await axios.post(`${API_ENDPOINT}/api/add-scheduled-course/`, scheduledCourseData, { headers });

    // Check for success response
    if (response.data.message) {
      setIsOverlayVisible(false);
      navigate('/plan'); // Example of navigating to a different page on success
    }
  } catch (error) {
    console.error('Error scheduling course:', error);
    // Check if it's a prerequisite error
    if (error.response && error.response.data.error) {
      alert(error.response.data.error);
    } else {
      // Handle other types of errors
      alert('An error occurred while scheduling the course. Please try again.');
    }
  }
};

const removeCourse = async () => {
  const isConfirmed = window.confirm("Are you sure you want to remove this course from your 4 Year Plan?");

  if (!isConfirmed) {
    return; // Exit the function if the user clicks "Cancel"
  }

  try {
    const headers = { Authorization: `Token ${token}`, 'Content-Type': 'application/json' };
    await axios.delete(`${API_ENDPOINT}/api/remove-scheduled-course/${id}/`, { headers });
    setIsCourseScheduled(false); // Update based on successful removal
    // Optionally, refresh data or show confirmation
  } catch (error) {
    console.error('Error removing course:', error);
  }
};


const courseForm = (
  <form onSubmit={addCourse}>
    <div className="mb-3">
      <label htmlFor="year" className="form-label" style={{ fontWeight: 'bold' }}>Year</label>
      <select
        className="form-control"
        id="year"
        name="year"
        value={year}
        onChange={(e) => setYear(e.target.value)}
      >
        <option value="">Select Year</option>
        {academicYearOptions.map((label, index) => (
          <option key={index} value={label}>{label}</option>
        ))}
      </select>
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Button type="submit" className="btn btn-primary">
        Add Course to Four Year Plan
      </Button>
    </div>
  </form>
);

  

  const [isHovered, setIsHovered] = React.useState(false);

  const styles = {
    container: {
      width: '100%',
    //   marginBottom: '20px',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'row', // Ensuring the children are laid out in a row
      alignItems: 'flex-start',
    },
    content: {
      marginLeft: '10px',
      display: 'flex',
      flexDirection: 'column', // Laying out the content in a column
      alignItems: 'flex-start', // Aligning content to the start
      marginBottom: '100px',

    },
    h3: {
      marginTop: '0',      
    },
    schoolLink: {
      display: 'inline-block',
      margin: '10px 0',
      fontWeight: '600',
      color: '#00008A', // Night Sky Blue
      textDecoration: 'none',
    },
    requirement: {
      padding: '5px 10px',
      borderRadius: '15px',
      color: '#FFFFFF',
    },
    description: {
        textAlign: 'left', // Explicitly setting text alignment to left
      },

    topBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '50px',
    marginTop: '50px',
    },
    buttonsContainer: {
    display: 'flex',
    gap: '20px', // Add space between buttons
    },

    backButton: {
    fontSize: '40px',
    marginRight: '20px',
    cursor: 'pointer',
    },

    backButtonContainer: {
        cursor: 'pointer',
        transition: 'color 0.3s', // Smooth transition for color
        color: isHovered ? '#00008A' : 'black', // Night Sky Blue color on hover
      },

    iconContainer: {
        position: 'relative',
        display: 'inline-block',
        borderRadius: '50%',
        padding: '10px',
        backgroundColor: isHovered ? '#E6EFFF' : '#FFF', // Lighter blue background on hover
        color: isHovered ? '#00008A' : 'black', // Night Sky Blue color on hover
        transition: 'background-color 0.3s, color 0.3s', // Smooth transition for background-color and color
    },
    requirementsContainer: {
        display: 'flex',
        flexDirection: 'column', // Set the direction to column
        gap: '20px', // Set the gap between requirements
        marginTop: '10px',
        width: '100%',
      },
      requirementsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginTop: '10px',
        marginBottom: '10px', // Added bottom margin
      },
      requirement: {
          padding: '0px 10px',
          borderRadius: '5px',
          color: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        modeTag: {
          backgroundColor: '#e0e0e0',
          color: '#333',
          padding: '2px 8px',
          borderRadius: '4px',
          display: 'inline-block',
          fontSize: '0.8em',
        },
        requirement: {
          padding: '0px 10px',
          borderRadius: '5px',
          color: '#000',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#FFE27B', // This color is for prerequisites in your CourseCard
        },
        strong: {
          display: 'block',
          margin: '5px 0',
        },
        requirementsContainer: {
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          marginTop: '10px',
          marginBottom: '10px',
        },
        schoolAndModeContainer: {
          display: 'flex',
          alignItems: 'center',
          gap: '10px', // Adjust the space between the school name and the mode tag as needed
        },
    
    };

    console.log(course)


  const processRequirements = () => {
    let displayReqs = [];
  
    // Updated structure to hold both names and colors
    const uniqueReqs = new Map();
  
    // Handle non-duplicable groups
    course.non_duplicables.forEach((nonDupGroup) => {
      const groupNames = nonDupGroup.requirements.map(req => req.name).join(' OR ');
      const groupColors = nonDupGroup.requirements.map(req => req.pathway_colors ? req.pathway_colors[0] : 'defaultColor');
      // Assuming the same color for all requirements in a group, or picking the first one's color
      const color = groupColors[0]; // This can be adjusted based on how you want to handle colors for grouped requirements
      // Add to the uniqueReqs map to ensure no duplicates, storing both name and color
      nonDupGroup.requirements.forEach(req => uniqueReqs.set(req.uuid, { name: groupNames, color: color, pathway: req.pathway_names[0] }));
    });
  
    // Add individual requirements if they are not part of any non-duplicable group
    course.requirements.forEach(req => {
      console.log(req);
      if (!uniqueReqs.has(req.uuid)) {
        const color = req.pathway_colors && req.pathway_colors[0] ? req.pathway_colors[0] : 'defaultColor';
        const pathwayName = req.pathway_names[0];
        uniqueReqs.set(req.uuid, { name: req.name, color: color, pathway: pathwayName });
      }
    });
  
    // Convert the unique requirements map to an array for rendering, preserving both name and color
    uniqueReqs.forEach((value, key) => {
      if (!displayReqs.find(req => req.name === value.name)) { // Ensure no duplicate groups are added
        displayReqs.push(value);
      }
    });
  
    return displayReqs;
  };


  return (
    <div className="page-container">
      {isOverlayVisible && (
        <InputOverlay
          title="Add Course"
          description="Please select the year to take this course in your Academic Plan."
          onClose={toggleOverlay}
          content={courseForm}
        />
      )}
  
      <div style={styles.container}>
        {!isLoading && course ? (
          <div style={styles.content}>
            <h1 style={styles.h3}>{course.course_id + ": " + course.title}</h1>
            <div style={styles.schoolAndModeContainer}>
              <h3 style={styles.strong}>{course.school.name}</h3>
              {course.mode && (
                <span style={styles.modeTag}>{course.mode}</span>
              )}
            </div>
            <p style={styles.description}>{course.description}</p>
            
            <div>
              {/* Conditionally render prerequisites if they exist */}
              {course.prerequisites && course.prerequisites.length > 0 && (
                <>
                  <strong style={styles.strong}>Prerequisites</strong>
                  <div style={styles.requirementsContainer}>
                    {course.prerequisites.map((prerequisite, index) => (
                      <div key={index} style={styles.requirement}>
                        {`${prerequisite.course_id}: ${prerequisite.title}`}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
  
            <strong>Requirements</strong>
            <div style={styles.requirementsContainer}>
              {processRequirements().map((req, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      ...styles.requirement,
                      backgroundColor: '#FFE27B',
                    }}
                  >
                    {`${req.pathway}: ${req.name}`}
                  </div>
                );
              })}
            </div>
            <Button onClick={isCourseScheduled ? removeCourse : toggleOverlay}>
              {isCourseScheduled ? 'Remove from Academic Plan' : 'Add Course to Academic Plan'}
            </Button>
          </div>
        ) : (
          <p>Loading course details...</p>
        )}
      </div>
    </div>
  );  
}

export default CoursePage;
  
