import React from 'react';
import ryan_profile from '../../assets/images/ryan_profile.jpeg';
import chelsea_profile from '../../assets/images/chelsea_profile.jpg';

function ContactAbout() {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        textAlign: 'center',
    };

    const headerStyle = {
        fontSize: window.innerWidth <= 767 ? '2.25rem' : '3.5rem',
    fontWeight: 'bold',
    marginBottom: window.innerWidth <= 767 ? '20px' : '40px',
    textAlign: 'center',
    };

    const personStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '600px',
        marginBottom: '40px',
    };

    const imageStyle = {
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        marginBottom: '20px',
    };

    const nameStyle = {
        fontSize: '1.5em',
        fontWeight: '600',
        marginBottom: '10px',
    };

    const titleStyle = {
        fontSize: '1.2em',
        fontWeight: '400',
        color: '#666',
        marginBottom: '20px',
    };

    const buttonContainerStyle = {
        display: 'flex',
        gap: '10px',
        marginBottom: '20px',
    };

    const iconButtonStyle = {
        backgroundColor: 'transparent',
        color: 'black',
        border: 'none',
        cursor: 'pointer',
        fontSize: '1.5em',
    };

    const handleCopyEmail = (email) => {
        navigator.clipboard.writeText(email);
        alert(`Copied ${email} to clipboard!`);
    };

    return (
        <div style={containerStyle}>
            <h1 style={headerStyle}>About Us</h1>

            <div style={personStyle}>
                <img 
                    src={ryan_profile}
                    alt="Ryan" 
                    style={imageStyle}
                />
                <div style={nameStyle}>Ryan, Co-Founder, CEO</div>
                <div style={titleStyle}>Ryan co-founded EdXcelerate, drawing on his experience as a designer and researcher at Ford, Dropbox, Bible Project, and SAP. With a BS in Science, Technology, and Society and an MS in Computer Science from Stanford University, Ryan’s passion for learning and innovation began early—he was the first student at his high school to earn an AA degree. He’s committed to expanding access to education and is passionate about faith, food, and sports.
                </div>
                <div style={buttonContainerStyle}>
                    <button style={iconButtonStyle} onClick={() => handleCopyEmail('ryan@edxcelerate.com')}>
                        <i className="fas fa-envelope"></i>
                    </button>
                    <a href="https://www.linkedin.com/in/ryan-foulke/" target="_blank" rel="noopener noreferrer" style={iconButtonStyle}>
                        <i className="fab fa-linkedin"></i>
                    </a>
                </div>
            </div>

            <div style={personStyle}>
                <img 
                    src={chelsea_profile}
                    alt="Chelsea" 
                    style={imageStyle}
                />
                <div style={nameStyle}>Chelsea, Co-Founder, CMO</div>
                <div style={titleStyle}>Chelsea co-founded EdXcelerate after three years as a classroom teacher. She holds a BS in Conservation and Resource Studies from UC Berkeley and a Master’s in Education from the University of Nevada, Reno. Chelsea was the first student at her high school to take dual enrollment courses, which fueled her belief in the power of self-directed learning. Outside of education, she’s an avid ultra marathon runner, skier, climber, and hiker.
                </div>
                <div style={buttonContainerStyle}>
                    <button style={iconButtonStyle} onClick={() => handleCopyEmail('chelsea@edxcelerate.com')}>
                        <i className="fas fa-envelope"></i>
                    </button>
                    <a href="https://www.linkedin.com/in/chelsea-torres-720b4b2b4/" target="_blank" rel="noopener noreferrer" style={iconButtonStyle}>
                        <i className="fab fa-linkedin"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ContactAbout;